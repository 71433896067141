import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Browsing and invoking services with DocService";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const MaxWidth = makeShortcode("MaxWidth");
const Tabs = makeShortcode("Tabs");
const TabPane = makeShortcode("TabPane");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "browsing-and-invoking-services-with-docservice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#browsing-and-invoking-services-with-docservice",
        "aria-label": "browsing and invoking services with docservice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Browsing and invoking services with `}<inlineCode parentName="h1">{`DocService`}</inlineCode></h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#debug-form"
          }}>{`Debug form`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#sending-http-headers"
            }}>{`Sending HTTP headers`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#including-and-excluding-service-methods"
          }}>{`Including and excluding service methods`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#example-requests-and-headers"
          }}>{`Example requests and headers`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#adding-docstrings"
          }}>{`Adding docstrings`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#for-thrift"
            }}>{`For Thrift`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#for-grpc"
            }}>{`For gRPC`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#for-annotated-service"
            }}>{`For annotated service`}</a></li>
        </ul>
      </li>
    </ul>
    <p><a parentName="p" {...{
        "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
      }}>{`type://DocService`}</a>{` is a single-page web application which provides the following useful features:`}</p>
    <ul>
      <li parentName="ul">{`Browsing the list of gRPC, Thrift or annotated services and their operations available in the server`}</li>
      <li parentName="ul">{`Invoking a service operation from a web form`}</li>
      <li parentName="ul">{`Creating a permalink for the invocation you've made`}</li>
    </ul>
    <p>{`First, add `}<a parentName="p" {...{
        "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
      }}>{`type://DocService`}</a>{` to the `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.grpc.GrpcSerializationFormats;
import com.linecorp.armeria.server.docs.DocService;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.thrift.THttpService;

ServerBuilder sb = Server.builder();
sb.http(8080);

// Add a Thrift service which implements 'ThriftHelloService'.
sb.service("/hello", THttpService.of(new MyThriftHelloService()));

// Add a gRPC service which implements 'GrpcHelloService'.
// Unlike Thrift, you must enable unframed requests explicitly.
sb.service(GrpcService.builder()
                      .addService(new MyGrpcHelloService())
                      .enableUnframedRequests(true)
                      .build());
// Add an annotated HTTP service.
sb.annotatedService("/service", new MyAnnotatedService());
// Add a DocService which scans all Thrift and gRPC services added to the server.
sb.serviceUnder("/docs", new DocService());
Server server = sb.build();
server.start().join();
`}</code></pre>
    <Tip mdxType="Tip">
      <p><a parentName="p" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` will scan for all supported services automatically when the `}<a parentName="p" {...{
          "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
        }}>{`type://Server`}</a>{` starts up.
Please see `}<a parentName="p" {...{
          "href": "#including-and-excluding-service-methods"
        }}>{`Including and excluding service methods`}</a>{`
to learn how to include or exclude certain services.`}</p>
    </Tip>
    <p>{`Open `}<a parentName="p" {...{
        "href": "http://127.0.0.1:8080/docs/"
      }}>{`http://127.0.0.1:8080/docs/`}</a>{` in your web browser and you'll see the following screen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "19.631901840490798%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAr0lEQVQY01WNyQqEQAxE/RuXS4///xUu3R5ED+rNmV5ccaOGjiha8KgkFBWHMYYwDGGdfT7kQRDA9/2b5/6cXde98TyPcKIoQhzHKIoCeZ6Tl2WJJEmQZRkE50jTFEIIcM7pbmebbZoGdV0TVVURjpQKbfvFMAxYlgXbtsFqHEfM80xM0/Tiuq/rSvl933HJkcrgJzW6rkPf91Rkg1prGGNe2MzJuSul6IEtPI6D+AMuCv/TqRAAqwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "docservice 1",
          "title": "docservice 1",
          "src": "/static/edbb0367cc9fd096091ee0bee93c125c/a6d36/docservice_1.png",
          "srcSet": ["/static/edbb0367cc9fd096091ee0bee93c125c/222b7/docservice_1.png 163w", "/static/edbb0367cc9fd096091ee0bee93c125c/ff46a/docservice_1.png 325w", "/static/edbb0367cc9fd096091ee0bee93c125c/a6d36/docservice_1.png 650w", "/static/edbb0367cc9fd096091ee0bee93c125c/e548f/docservice_1.png 975w", "/static/edbb0367cc9fd096091ee0bee93c125c/3c492/docservice_1.png 1300w", "/static/edbb0367cc9fd096091ee0bee93c125c/9b29b/docservice_1.png 3840w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`The left pane of the screen shows the list of the available services and their operations. If you defined
structs, enums or exceptions, they will appear there as well. If you click the `}<inlineCode parentName="p">{`hello()`}</inlineCode>{` operation, the
right pane will show the details of the operation such as the list of the parameters, the return type and
the exceptions which may be thrown:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.239263803680984%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBUlEQVQoz52R2UrEQBBF88XZ/8zBB39B50XGl0CQKAhOVpLOvly5xRRMVHAwcKmu7XRV2nIcB77vIwxDBEEAz/Pgui4Yp2zbFp81t8hq2xamabBtG66/ZVnwn8+6PyY4PL7i7ukNh+M7Hp4/UNUNiiLHPM8oyxLGGLmg6zo5Mz6OI4o83/m0MiELZVJjxDIxTRPWdZXYMAxyZqzve4FT9FnLMy23tNggoIslnLdVVSUNLCLsFu2BlwkJJEjX/N7wJ5AglQK5Yp7nUqTrcmrm2XQNVl/1K1AnJKSua4Gez584nV4QxzGiKEKSJJJnTsWNfgBpebM+jP545rIsE6Vpuns8Ffu+APPTnOA+Tl9/AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "docservice 2",
          "title": "docservice 2",
          "src": "/static/f8ed6a148d2f38765d626fe0bdf3a5e1/a6d36/docservice_2.png",
          "srcSet": ["/static/f8ed6a148d2f38765d626fe0bdf3a5e1/222b7/docservice_2.png 163w", "/static/f8ed6a148d2f38765d626fe0bdf3a5e1/ff46a/docservice_2.png 325w", "/static/f8ed6a148d2f38765d626fe0bdf3a5e1/a6d36/docservice_2.png 650w", "/static/f8ed6a148d2f38765d626fe0bdf3a5e1/e548f/docservice_2.png 975w", "/static/f8ed6a148d2f38765d626fe0bdf3a5e1/3c492/docservice_2.png 1300w", "/static/f8ed6a148d2f38765d626fe0bdf3a5e1/95515/docservice_2.png 3806w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`As you may have noticed, the 'description' column is empty. `}<a parentName="p" {...{
        "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
      }}>{`type://DocService`}</a>{` can even show the docstrings
you put into your `}<inlineCode parentName="p">{`.thrift`}</inlineCode>{` or `}<inlineCode parentName="p">{`.proto`}</inlineCode>{` files with a little bit of build configuration. We will visit this
later in this document.`}</p>
    <h2 {...{
      "id": "debug-form",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#debug-form",
        "aria-label": "debug form permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Debug form`}</h2>
    <p>{`Now, scroll down the right pane. You'll find a 'debug form' which enables you to send an RPC request in
human-friendly JSON format:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.94478527607362%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA80lEQVQoz52RQU7DMBBFfbAkiPZqvQPH6BIJRG/AAhU2bIqAqGpC1Sqxx7Hj5CPbHQgiDRIjPX3bmvnyzIjZfI7LWSTNLpAkCbIsQ5qm/0IU23eo6gA6IesaZVlCShlQSkFrDSIK6u989jrEv4nr+xfcPORYPe0CB2mgSUEbC9INrDUYhrUWUyHyjwpvxRGvJ6iJBX3ff2nXdUGdc0HH8Dke0WgFE6CAc+2vZA4uHAvOFTwrpm2jYceGgyL+6aShH3Lk23AscdjWubZ/GPJGjW2R7xU2RY3dkeD+MJg09NutVIOr22cslmvcPW5jq2faHNvyJ42Vk1x1ry0DAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "docservice 3",
          "title": "docservice 3",
          "src": "/static/eeec3b70c20527981c299569e9c20efa/a6d36/docservice_3.png",
          "srcSet": ["/static/eeec3b70c20527981c299569e9c20efa/222b7/docservice_3.png 163w", "/static/eeec3b70c20527981c299569e9c20efa/ff46a/docservice_3.png 325w", "/static/eeec3b70c20527981c299569e9c20efa/a6d36/docservice_3.png 650w", "/static/eeec3b70c20527981c299569e9c20efa/e548f/docservice_3.png 975w", "/static/eeec3b70c20527981c299569e9c20efa/3c492/docservice_3.png 1300w", "/static/eeec3b70c20527981c299569e9c20efa/0b986/docservice_3.png 3798w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Type the request parameters like the following and click the 'Submit to: /hello' button:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "Armeria"
}
`}</code></pre>
    <p>{`The result pane right next to the text area you entered the JSON request will show the reply in JSON:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.94478527607362%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKklEQVQoz42QTU7DMBCFc7AmiPZi3IELcAJ2SEhlywoWLNixqFRU1JKUpok9/omdh2aahDZSRUf6ZI09en5vkulshuspM0WaXWEymSDLMqRpOsB34/4cSb5egeoSpkOrGkVRQGsSiAjWWhhjhL4/R/LwssDj2wpP7xvhRzmQrkHGQZOB9w7H5ZxDiAFt26KN8XB2cCWrosLyu8RysxPIesQQ4J1D0zQyGGMUWMBYi6/tFirfgqoaxlkYInHPM4k1Gk4gIYTmxFH/c9eI4Geeoyz3MPsKtK9EjOPybKKUwjGDqz7KSNx7L7EZby2ctZKG70VQa40Df4Jjhz199OHtmG5mEGQxPvkn3utivcNmpxBGi/+PE0FebqUIt/fPuLmbY/76IU5ibHFp/QIjdJCY3I/rVQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "docservice 4",
          "title": "docservice 4",
          "src": "/static/840bf18cb3f368e29204729a1c15befd/a6d36/docservice_4.png",
          "srcSet": ["/static/840bf18cb3f368e29204729a1c15befd/222b7/docservice_4.png 163w", "/static/840bf18cb3f368e29204729a1c15befd/ff46a/docservice_4.png 325w", "/static/840bf18cb3f368e29204729a1c15befd/a6d36/docservice_4.png 650w", "/static/840bf18cb3f368e29204729a1c15befd/e548f/docservice_4.png 975w", "/static/840bf18cb3f368e29204729a1c15befd/3c492/docservice_4.png 1300w", "/static/840bf18cb3f368e29204729a1c15befd/0b986/docservice_4.png 3798w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`The current location of your web browser should be updated like the following:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`http://127.0.0.1:8080/docs/#!method/com.example.ThriftHelloService/hello?args=%7B%22name%22%3A%22Armeria%22%7D`}</inlineCode></li>
    </ul>
    <p>{`Imagine you build a request that reproduces the problem using the debug form and share the URL of the request
with your colleagues. It's way more convenient than traditional workflow for replaying an RPC request.`}</p>
    <h3 {...{
      "id": "sending-http-headers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sending-http-headers",
        "aria-label": "sending http headers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending HTTP headers`}</h3>
    <p>{`You may want to send an additional HTTP header such as `}<inlineCode parentName="p">{`authorization`}</inlineCode>{` when sending an RPC request via
a debug form. Click the 'Edit additional HTTP headers' link below the text area, and you'll see another
text area which allows you to specify the HTTP headers you want to add:`}</p>
    <MaxWidth value={432} mdxType="MaxWidth">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.171779141104295%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA7klEQVQoz52S247DIAxE8///uZemSkogAexgzgrSZrePG0vDWDYa+TYA+Kh8PyIf08bXIzK6xLgkbi7xOW/cl4RZbV+ptVKf/LJfDwYtlZsXRi8n34N2jEF77L4qSy74bCdC58KSCj4VXCw0rWETw0fBrCCq7PuOaoOyl0IxQ0QR3Xs+ZSHLwUe+9mqDGFGNoT1bVtYQmOcZ7z2Lczjn2NaVlBLTNBFCIATf/ZZrLCJnq0lPwcqyJmo1rthrlvEluKmR9yNo9Sj/v3gTjF3wWnV/7Wy5LWUV69W1AV9BX0ouh2BbtUvvJ3EFj342xg8QnMKC4gricwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "docservice 5",
            "title": "docservice 5",
            "src": "/static/c02a19054aff506d73565f040d3221b4/a6d36/docservice_5.png",
            "srcSet": ["/static/c02a19054aff506d73565f040d3221b4/222b7/docservice_5.png 163w", "/static/c02a19054aff506d73565f040d3221b4/ff46a/docservice_5.png 325w", "/static/c02a19054aff506d73565f040d3221b4/a6d36/docservice_5.png 650w", "/static/c02a19054aff506d73565f040d3221b4/e548f/docservice_5.png 975w", "/static/c02a19054aff506d73565f040d3221b4/3c492/docservice_5.png 1300w", "/static/c02a19054aff506d73565f040d3221b4/6774c/docservice_5.png 1391w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </MaxWidth>
    <h2 {...{
      "id": "including-and-excluding-service-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#including-and-excluding-service-methods",
        "aria-label": "including and excluding service methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Including and excluding service methods`}</h2>
    <p>{`You can include or exclude service methods using `}<a parentName="p" {...{
        "href": "type://DocServiceFilter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceFilter.html"
      }}>{`type://DocServiceFilter`}</a>{` when building a `}<a parentName="p" {...{
        "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
      }}>{`type://DocService`}</a>{`
with a `}<a parentName="p" {...{
        "href": "type://DocServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceBuilder.html"
      }}>{`type://DocServiceBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.docs.DocService;
import com.linecorp.armeria.server.docs.DocServiceFilter;

ServerBuilder sb = Server.builder();
...
sb.serviceUnder("/docs", DocService.builder()
                                   // Include Thrift services and Annotated services.
                                   .include(DocServiceFilter.ofThrift().or(
                                        DocServiceFilter.ofAnnotated()))
                                   // Exclude the method whose name is "foo" in Thrift services.
                                   .exclude(DocServiceFilter.ofThrift().and(
                                        DocServiceFilter.ofMethodName("foo")))
                                   .build());
...
`}</code></pre>
    <p>{`The inclusion rule is as follows:`}</p>
    <ul>
      <li parentName="ul">{`No `}<inlineCode parentName="li">{`include(DocServiceFilter)`}</inlineCode>{` and `}<inlineCode parentName="li">{`exclude(DocServiceFilter)`}</inlineCode>{` is called: include all methods.`}</li>
      <li parentName="ul">{`Only `}<inlineCode parentName="li">{`exclude(DocServiceFilter)`}</inlineCode>{` is called: include all methods except the methods which the exclusion filter returns `}<inlineCode parentName="li">{`true`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Only `}<inlineCode parentName="li">{`include(DocServiceFilter)`}</inlineCode>{` is called: include the methods which the inclusion filter returns `}<inlineCode parentName="li">{`true`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`include(DocServiceFilter)`}</inlineCode>{` and `}<inlineCode parentName="li">{`exclude(DocServiceFilter)`}</inlineCode>{` is called: include the methods which the inclusion filter returns `}<inlineCode parentName="li">{`true`}</inlineCode>{` and the exclusion filter returns `}<inlineCode parentName="li">{`false`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "example-requests-and-headers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-requests-and-headers",
        "aria-label": "example requests and headers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example requests and headers`}</h2>
    <p>{`You can specify the example requests and HTTP headers which will be used as the default value of the debug form
with a `}<a parentName="p" {...{
        "href": "type://DocServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceBuilder.html"
      }}>{`type://DocServiceBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpHeaders;
import static com.linecorp.armeria.common.HttpHeaderNames.AUTHORIZATION;

ServerBuilder sb = Server.builder();
...
sb.serviceUnder(
    "/docs",
    DocService.builder()
              // HTTP headers for all services
              .exampleHeaders(HttpHeaders.of(AUTHORIZATION, "bearer b03c4fed1a"))
              // Thrift example request for 'ThriftHelloService.hello()'
              .exampleRequests(List.of(new ThriftHelloService.hello_args("Armeria")))
              // gRPC example request for 'GrpcHelloService.Hello()'
              .exampleRequests(GrpcHelloServiceGrpc.SERVICE_NAME, "Hello", // Method name
                               HelloRequest.newBuilder().setName("Armeria").build())
              .build());
...
`}</code></pre>
    <p>{`By adding examples to `}<a parentName="p" {...{
        "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
      }}>{`type://DocService`}</a>{`, your users will be able to play with the services you wrote
without a hassle and thus will understand them sooner and better.`}</p>
    <h2 {...{
      "id": "adding-docstrings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-docstrings",
        "aria-label": "adding docstrings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding docstrings`}</h2>
    <p>{`Configure your build script so that it generates the required metadata file for your `}<inlineCode parentName="p">{`.thrift`}</inlineCode>{` or `}<inlineCode parentName="p">{`.proto`}</inlineCode>{`
files into the specific location in the class path.`}</p>
    <h3 {...{
      "id": "for-thrift",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#for-thrift",
        "aria-label": "for thrift permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`For Thrift`}</h3>
    <ul>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`--gen json`}</inlineCode>{` option to generate the `}<inlineCode parentName="li">{`.json`}</inlineCode>{` file that contains the docstrings.`}</li>
      <li parentName="ul">{`Put the generated `}<inlineCode parentName="li">{`.json`}</inlineCode>{` file into the `}<inlineCode parentName="li">{`META-INF/armeria/thrift`}</inlineCode>{` directory.`}</li>
    </ul>
    <h3 {...{
      "id": "for-grpc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#for-grpc",
        "aria-label": "for grpc permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`For gRPC`}</h3>
    <Tip mdxType="Tip">Note that .bin, .desc, .dsc, .pb and .protobin are acceptable descriptor set file extensions.</Tip>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Configure the protobuf plugin to generate the `}<inlineCode parentName="p">{`.dsc`}</inlineCode>{` file that contains the docstrings and
put it into the `}<inlineCode parentName="p">{`META-INF/armeria/grpc`}</inlineCode>{` directory:`}</p>
        <Tabs mdxType="Tabs">
  <TabPane tab="Gradle" key="gradle" mdxType="TabPane">
    <CodeBlock language="groovy" filename="build.gradle" mdxType="CodeBlock">{`
    protobuf {
        generateProtoTasks {
            all().each { task ->
                task.generateDescriptorSet = true
                task.descriptorSetOptions.includeSourceInfo = true
                task.descriptorSetOptions.includeImports = true
                task.descriptorSetOptions.path =
                        "\${buildDir}/resources/main/META-INF/armeria/grpc/service-name.dsc"
            }
        }
    }
    `}
    </CodeBlock>
  </TabPane>
  <TabPane tab="Gradle (Kotlin)" key="gradle_kotlin" mdxType="TabPane">
    <CodeBlock language="kotlin" filename="build.gradle.kts" mdxType="CodeBlock">{`
    protobuf {
        generateProtoTasks {
            all().forEach {
                it.generateDescriptorSet = true
                it.descriptorSetOptions.includeSourceInfo = true
                it.descriptorSetOptions.includeImports = true
                it.descriptorSetOptions.path =
                        "\${buildDir}/resources/main/META-INF/armeria/grpc/service-name.dsc"
            }
        }
    }
    `}</CodeBlock>
  </TabPane>
  <TabPane tab="Maven" key="maven" mdxType="TabPane">
    <CodeBlock language="xml" filename="pom.xml" mdxType="CodeBlock">{`
    <!-- See https://www.xolstice.org/protobuf-maven-plugin/usage.html for more information. -->
    <plugin>
      <groupId>org.xolstice.maven.plugins</groupId>
      <artifactId>protobuf-maven-plugin</artifactId>
      <version>0.6.1</version>
      <configuration>
        <protocArtifact>com.google.protobuf:protoc:\${protoc.version}:exe:\${os.detected.classifier}</protocArtifact>
        <pluginId>grpc-java</pluginId>
        <pluginArtifact>io.grpc:protoc-gen-grpc-java:\${grpc.version}:exe:\${os.detected.classifier}</pluginArtifact>
        <writeDescriptorSet>true</writeDescriptorSet>
        <includeDependenciesInDescriptorSet>true</includeDependenciesInDescriptorSet>
        <includeSourceInfoInDescriptorSet>true</includeSourceInfoInDescriptorSet>
        <descriptorSetOutputDirectory>\${project.build.outputDirectory}/META-INF/armeria/grpc</descriptorSetOutputDirectory>
        <descriptorSetFileName>\${project.build.finalName}.dsc</descriptorSetFileName>
      </configuration>
      <executions>
        <execution>
          <goals>
            <goal>compile</goal>
            <goal>compile-custom</goal>
          </goals>
        </execution>
      </executions>
    </plugin>
    `}</CodeBlock>
  </TabPane>
        </Tabs>
      </li>
    </ul>
    <h3 {...{
      "id": "for-annotated-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#for-annotated-service",
        "aria-label": "for annotated service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`For annotated service`}</h3>
    <p>{`  Using `}<a parentName="p" {...{
        "href": "type://@Description:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Description.html"
      }}>{`type://@Description`}</a>{` annotation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.annotation.Description;

@Description(value = "### A service that provides user information.", markup=Markup.MARKDOWN)
public class UserService {

    @Get("/users/{id}")
    @Description("Retrieves the user information by the given user ID.")
    public User getUser(
          @Param("id") @Description("the user ID") String id,
          @Param("userType") @Description("the user type") UserType userType
    ) { ... }

    ...
}

public class User {
    @Description("User Identification")
    private String id;

    @Description("User name")
    private String name;
}

@Description(value = "flowchart LR\\n" +
                     " ADMIN --- USER",
             markup = Markup.MERMAID)
public enum UserType {
    @Description(value = "\`Admin\` type", markup = Markup.MARKDOWN)
    ADMIN,
    @Description(value = "\`User\` type", markup = Markup.MARKDOWN)
    USER;
}
`}</code></pre>
  <Tip mdxType="Tip">
      <p>{`  You can render the description as `}<a parentName="p" {...{
          "href": "https://github.github.com/gfm/"
        }}>{`Markdown`}</a>{` or `}<a parentName="p" {...{
          "href": "https://mermaid-js.github.io/mermaid/#/"
        }}>{`Mermaid`}</a>{` using `}<a parentName="p" {...{
          "href": "type://Markup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/Markup.html"
        }}>{`type://Markup`}</a>{`.`}</p>
  </Tip>
    <p>{`  Using JavaDoc:`}</p>
    <p>{`  This only works with method parameters.
To enable it, you have to add the armeria annotation processor to your project:`}</p>
  <Tabs mdxType="Tabs">
    <TabPane tab="Gradle" key="gradle" mdxType="TabPane">
      <CodeBlock language="groovy" filename="build.gradle" mdxType="CodeBlock">{`
      dependencies {
          compileOnly 'com.linecorp.armeria:armeria-annotation-processor'
          annotationProcessor 'com.linecorp.armeria:armeria-annotation-processor'
      }
      `}
      </CodeBlock>
    </TabPane>
    <TabPane tab="Gradle (Kotlin)" key="gradle_kotlin" mdxType="TabPane">
      <CodeBlock language="kotlin" filename="build.gradle.kts" mdxType="CodeBlock">{`
      plugins {
          kotlin("kapt") apply true
      }\n
      dependencies {
          configurations["kapt"].dependencies.add("com.linecorp.armeria:armeria-annotation-processor")
      }\n
      kapt {
          annotationProcessor("com.linecorp.armeria.server.annotation.processor.DocumentationProcessor")
      }
      `}</CodeBlock>
    </TabPane>
  </Tabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      